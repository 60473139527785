import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

const UseRedirectToHttps = () => {
  // let location = useLocation();
  // useEffect(() => {
  //   console.log('here');
  //   if (location !== undefined && window.location.protocol !== 'https:') {
  //     window.location.replace('https://www.ivain-bordeneuve.com' + location.pathname);
  //   }
  // });
};

export default UseRedirectToHttps;
